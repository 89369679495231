import { createStore } from 'vuex'
import { projectAuth, projectFirestore , projectFunctions} from '../firebase/config';
import router from '../router';
import {slide_pop_successs} from '../Methods/Msgs';
import { collect_location_data} from '../Methods/Users';
import {save_cart_to_cloud, load_latest_cart,update_user_push_token} from '../Methods/Users'
import {get_valids_promotions} from '../Methods/Promotions_funcs'



export default createStore({
  state: {
    user: null,
    role: 0,
    platform:null,
    loader: false,
    show_menu: false,
    show_support_chat: false,
    push_token: null,
    departments: null,
    admin_departments: null,
    products: null,
    client:{
      client_name:'',
      promotions:[]
    },
    sales: [],
    t_categories: [],
    t_subs: [],
    client_cart: {
      uid: null,
      user_uid: null,
      items: [],
      notes: '',
      total_cost: 0,
      date: new Date(),
      client: null,
      assortment: null,
      note_office: '',
      note_logistics: '',
      GPSX: 0,
      GPSY: 0,
      prices: true
    },
    possition: []
  },
  actions: {
    async set_user({ commit }, user){
      if(user){
        this.state.user = await projectFirestore.collection('Users').doc(user.uid).get()
        .then(doc => {
          if(doc.exists){
            slide_pop_successs(`ברוך הבא ${doc.data().name}`)
            this.dispatch('get_role');
            projectFunctions.httpsCallable('visibility_state')({state: 'visible'})
            return doc.data();
          }
        })
        if(this.state.user && Number(this.state.user.agent) > 0){
          collect_location_data();
        }
        if(this.state.user && this.state.user.assortment){
          this.state.client_cart.assortment = this.state.user.assortment
        }
        if(this.state.user && this.state.user.client){
          this.state.client_cart.client = this.state.user.client
        }
      }else{
        this.state.user = null;
        this.state.role = 0;
        console.log('No User.');
        console.log(window.location.href,window.location.href.includes('shlush'));
        if(window.location.href.includes('branches') || window.location.href.includes('shlush') || window.location.href.includes('privacy') || window.location.href.includes('terms')
        || window.location.href.includes('computer-service') || window.location.href.includes('sales_catalog') || window.location.href.includes('club')){
          return
        }
        router.replace({name:'Home'});
      }
    },
    
    async refresh_profile({commit}){
      if(this.state.user){
        this.state.user = await projectFirestore.collection('Users').doc(projectAuth.currentUser.uid).get()
        .then(doc => {
          if(doc.exists){
            return doc.data();
          }
        })
        if(this.state.user.client){
          this.state.user.client_data = await projectFirestore.collection('Clients').doc(this.state.user.client).get()
          .then(doc => {
            if(doc.exists){
              return doc.data();
            }
          })
        }
      }
      this.state.products = null;
      if(this.state.user && Number(this.state.user.agent) > 0){
        collect_location_data();
      }
      if(this.state.user){
        update_user_push_token();
      }
    },

    async get_role({commit}){
      const _role = await projectFunctions.httpsCallable('check_role')();
      this.state.role = _role.data
    },

    toggle_menu(){
      if(!this.state.show_menu){
        this.state.show_menu = !this.state.show_menu
      }else{
        const el = document.getElementById('main-menu')
        if(el){
          el.classList.replace('slide-in-right', 'slide-out-right')
          setTimeout(() => {
            this.state.show_menu = !this.state.show_menu
          }, 500);
        }
      }
    },

    async get_departments(){
      console.log('Deps..');
      const _docs = await projectFirestore.collection('Departments').get()      
      if(!_docs.empty){
        var deps = _docs.docs.map(doc => doc.data())
        deps.sort((aa, bb) => {
          return aa.order - bb.order
        })
        let all_deps = deps
        let fixed_deps = [];
        for(let i = 0; i< all_deps.length ; i++){
          if(this.state.t_categories.includes(all_deps[i].uid)){
            fixed_deps.push(all_deps[i])
          }
        }
        this.state.departments = fixed_deps;
        this.state.loader = false;
      }
    },

    async get_departments_admin(){
      const _docs = await projectFirestore.collection('Departments').get()      
      if(!_docs.empty){
        var deps = _docs.docs.map(doc => doc.data())
        deps.sort((aa, bb) => {
          return aa.order - bb.order
        })
        let all_deps = deps
        this.state.admin_departments = all_deps;
        this.state.loader = false;
      }
    },

    async get_products(){
      this.state.products = null;
      let categories = [];
      let subs = [];

      this.state.loader = true;

      if(!this.state.user || !this.state.user.assortment){
        console.log('Fetching 10001 Assortment.');
        let items = [];
        categories = [];
        subs = [];
        let general_assortment = (await projectFirestore.collection('Assortments').doc('10001').get()).data().items
        const docs = await projectFirestore.collection('Products').get()
        if(!docs.empty){
          docs.forEach(doc => {
            if(general_assortment.includes(doc.data().makat) && doc.data().showOnWeb != 'H'){
              items.push(doc.data());
              items[items.length - 1].order_amount = 0;
              
              if(!categories.includes(doc.data().category)){
                categories.push(doc.data().category)
              }
              if(!subs.includes(doc.data().category)){
                subs.push(doc.data().sub_category)
              }
            }
          })
        }
        if(this.state.user && this.state.user.client_data && this.state.user.client_data.products_special == 'Y'){
          // יש פריטים ללקוח בלבד!
          console.log('Client has Own items ACTIVE!');
          console.log('No assortment!');
        }
        this.state.products = items;
      }

      if(this.state.user && this.state.user.assortment){
        categories = [];
        subs = [];
        console.log(`Fetching ${this.state.user.assortment} Assortment.`);
        
        let items = [];
        const assortment_db = await projectFirestore.collection('Assortments').doc(this.state.user.assortment).get()
        let general_assortment = await assortment_db.data().items
        if(assortment_db.data().prices){
          this.state.client_cart.prices = true
        }else{
          this.state.client_cart.prices = false
        }

        const docs = await projectFirestore.collection('Products').get()
        if(!docs.empty){
          docs.forEach(doc => {
            if(general_assortment.includes(doc.data().makat) && doc.data().showOnWeb != 'H'){
              items.push(doc.data());
              items[items.length - 1].order_amount = 0;
              //מנקה הנחות אם יש מחירון או לקוח מרכז שהוא לא 10001
              if(this.state.user.assortment != '10001' || (this.state.user.price_list && this.state.user.price_list != 'בסיס')){
                items[items.length - 1].prices = [];
              }
              if(!categories.includes(doc.data().category)){
                categories.push(doc.data().category)
              }
              if(!subs.includes(doc.data().sub_category)){
                subs.push(doc.data().sub_category)
              }
            }
          })
          if(this.state.user.price_list && this.state.user.price_list != 'בסיס'){
            let price_list = await projectFirestore.collection('Price_Lists').doc(this.state.user.price_list).get()
            if(price_list.exists){
              let list = price_list.data().items
              for(let i =0; i< list.length; i++){
                let makat = list[i].makat;
                let price = list[i].price;
                let inx = items.findIndex(itm => {
                  return itm.makat == makat
                })
                if(inx != -1){
                  items[inx].basePrice = price
                }
              }
            }
          }

          if(this.state.user.client_data && this.state.user.client_data.products_special == 'Y'){
            // יש פריטים ללקוח בלבד!
            console.log('Client has Own items ACTIVE!');
            if(this.state.user.client_data.client_products && this.state.user.client_data.client_products.length > 0){
              let special_items = [];
              for(let f=0; f<this.state.user.client_data.client_products.length ; f++){
                let _itx = this.state.user.client_data.client_products[f]
                if(!_itx.valid){
                  let inx = items.findIndex(ii => ii.makat == _itx.makat)
                  if(inx != -1){
                    special_items.push(items[inx])
                  }
                }
              }
              if(special_items.length > 0){
                items = special_items
              }
            }
          }
        }
        this.state.products = items;
      }

      this.state.t_categories = categories;
      this.state.t_subs = subs;
    },

    async check_rosman_sales(){
      if(this.state.user && this.state.user.client){
        console.log('Checking agent sales');
        console.log('Client: ' + this.state.user.client);
        for(let i = 0; i< this.state.products.length; i++){
          if(this.state.products[i].supllier =='205780' || this.state.products[i].supllier =='205745' || this.state.products[i].supllier =='205720'){
            if(this.state.products[i].showOnWeb == 'Y'){
              let res = await projectFunctions.httpsCallable("check_product_sales")({makat: this.state.products[i].makat, client: this.state.user.client})
              if(res.data && res.data.PARTTRANSLAST2_SUBFORM && res.data.PARTTRANSLAST2_SUBFORM.length > 0){
                this.state.products[i].rosman_sold = true
              }else{
                this.state.products[i].rosman_sold = false
              }
            }
          }
        }
      }
    },
  
    async get_sales(){
      console.log('Sales!');
      if(this.state.user && this.state.user.client_data && this.state.user.client_data.merakez){
        let sales = [];
        const docs = await projectFirestore.collection('Sales').where('merakez','==', this.state.user.client_data.merakez).get()
        if(!docs.empty){
          docs.docs.forEach(doc => {
            if((new Date(doc.data().end_date).setHours(23, 59, 0) >= new Date()) && (new Date(doc.data().start_date).setHours(0, 1, 0) <= new Date())){
              let sale = doc.data()
              sales.push(sale)
              sale.makats_arr.forEach(item => {
                let inx = this.state.products.findIndex(it => {
                  return it.makat == item
                })
                if(inx != -1){
                  this.state.products[inx].sale = sale;
                  this.state.products[inx].prices = [];
                }
              })
            }
          })
        }
        this.state.sales = sales;
      }else{
        let sales = [];
        const docs = await projectFirestore.collection('Sales').where('merakez','==', '10001').get()
        if(!docs.empty){
          docs.docs.forEach(doc => {
            if(new Date(doc.data().end_date).setHours(23, 59, 0) >= new Date()){
              let sale = doc.data()
              sales.push(sale)
              sale.makats_arr.forEach(item => {
                let inx = this.state.products.findIndex(it => {
                  return it.makat == item
                })
                if(inx != -1){
                  this.state.products[inx].sale = sale;
                  this.state.products[inx].prices = [];
                }
              })
            }
          })
        }
        this.state.sales = sales;
      }
    },
    async terminate_user_chat(){
      this.state.show_support_chat = false
      await projectFirestore.collection('Chat').doc('Pending').collection('PendingRequests')
      .doc(projectAuth.currentUser.uid).delete()
      localStorage.removeItem('user_chat');
    },
    async get_promotions(){
      const current_client = (this.state.user && this.state.user.client) || null 
      if(current_client){
        console.log('dispach promotions');
        const current_client_name = this.state.user.client_data.name
        console.log('current_client:',current_client);
        const promotions = await get_valids_promotions(true)
        console.log('all promotions:', promotions);
        this.state.client.client_name = current_client_name
        this.state.client.promotions = promotions.filter(promotion=>{
          if(promotion.client_type=='כל הלקוחות' && !promotion.ignore.includes(current_client)) return promotion
          if(promotion.clients.includes(current_client) && !promotion.ignore.includes(current_client)) return promotion
        })
      }
    }
  },
  getters: {
    loader(state){
      return state.loader
    },
    show_menu(state){
      return state.show_menu
    },
    user(state){
      return state.user
    },
    role(state){
      return state.role
    },
    departments(state){
      return state.departments
    },
    admin_departments(state){
      return state.admin_departments
    },
    products(state){
      return state.products
    },
    client_cart(state){
      return state.client_cart
    },
    sales(state){
      return state.sales
    }
  }
})
